const maybeApiUrl = process.env.REACT_APP_API_URL;
const maybeGoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

if (!maybeApiUrl) {
  throw new Error("REACT_APP_API_URL is not set in the environment");
}
if (!maybeGoogleClientId) {
  throw new Error("REACT_APP_GOOGLE_CLIENT_ID is not set in the environment");
}

export const apiUrl: string = maybeApiUrl;
export const googleClientId: string = maybeGoogleClientId;
