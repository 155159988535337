import { apiUrl } from "./config";

class ApiClient {
  async request<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const url = `${apiUrl}${endpoint}`;
    const response = await fetch(url, {
      ...options,
      credentials: "include",
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      // Session is invalid or expired
      localStorage.removeItem("user");
      window.dispatchEvent(new Event("userLoggedOut"));
      throw new Error("Authentication failed. Please log in again.");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  // GET request
  async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, { method: "GET" });
  }

  // POST request
  async post<T>(endpoint: string, data: any, isFormData = false): Promise<T> {
    const url = `${apiUrl}${endpoint}`;
    const headers: HeadersInit = {};

    if (!isFormData) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers,
      body: isFormData ? data : JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  async getMessage(): Promise<{ message: string }> {
    return this.get<{ message: string }>("/");
  }

  async createComplianceMatrix(file: File): Promise<{ message: string }> {
    const formData = new FormData();
    formData.append("file", file);

    return this.post<{ message: string }>("/create-compliance-matrix", formData, true);
  }

  async summarisePDF(file: File): Promise<{ message: string }> {
    const formData = new FormData();
    formData.append("file", file);

    return this.post<{ message: string }>("/summarise", formData, true);
  }

  async getUser(): Promise<any> {
    const response = await fetch(`${apiUrl}/user`, {
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Not authenticated");
    }
    return response.json();
  }

  async listDriveFiles(): Promise<{
    files: Array<{ id: string; name: string; mimeType: string; modifiedTime: string }>;
  }> {
    return this.get<{ files: Array<{ id: string; name: string; mimeType: string; modifiedTime: string }> }>(
      "/drive/files"
    );
  }
}

export const apiClient = new ApiClient();

export type { ApiClient };
