import { useState, useEffect } from "react";
import { apiClient } from "./apiClient";

interface User {
  name: string;
  email: string;
  picture: string;
}

export function useGoogleAuth() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Check user session on mount
    apiClient
      .getUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => {
        setUser(null);
      });

    // Listen for logout events
    const handleLogout = () => setUser(null);
    window.addEventListener("userLoggedOut", handleLogout);

    return () => {
      window.removeEventListener("userLoggedOut", handleLogout);
    };
  }, []);

  const logout = async () => {
    setUser(null);
  };

  return {
    user,
    logout,
  };
}
