import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import "./App.css";
import { useGoogleAuth } from "./useGoogleAuth";
import { apiClient } from "./apiClient";
import { apiUrl } from "./config";

function AppContent() {
  const [message, setMessage] = useState<string>("");
  const { user, logout } = useGoogleAuth();
  const [imageError, setImageError] = useState(false);
  const [complianceMatrixStatus, setComplianceMatrixStatus] = useState<string | null>(null);
  const [summaryStatus, setSummaryStatus] = useState<string | null>(null);
  const [driveFiles, setDriveFiles] = useState<
    Array<{
      id: string;
      name: string;
      mimeType: string;
      modifiedTime: string;
    }>
  >([]);

  useEffect(() => {
    if (user) {
      apiClient
        .getMessage()
        .then((data: { message: string }) => setMessage(data.message))
        .catch((error: Error) => {
          console.error("Error fetching data:", error);
          setMessage("Something seems to be broken.");
        });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      apiClient
        .listDriveFiles()
        .then(({ files }) => setDriveFiles(files))
        .catch((error) => console.error("Error fetching drive files:", error));
    }
  }, [user]);

  const handleComplianceMatrixGeneration = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const file = formData.get("file") as File;

    if (file) {
      try {
        setComplianceMatrixStatus("Creating compliance matrix...");
        const result = await apiClient.createComplianceMatrix(file);
        setComplianceMatrixStatus(result.message);
      } catch (error) {
        console.error("Upload failed:", error);
        setComplianceMatrixStatus(`Upload failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    }
  };

  const handleSummarize = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const file = formData.get("file") as File;

    if (file) {
      try {
        setSummaryStatus("Generating summary...");
        const result = await apiClient.summarisePDF(file);
        setSummaryStatus(result.message);
      } catch (error) {
        console.error("Summary failed:", error);
        setSummaryStatus(`Summary generation failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    }
  };

  const handleLogin = () => {
    window.location.href = `${apiUrl}/auth/google/login`;
  };

  return (
    <div className="h-screen bg-ox-white text-ox-black font-sans">
      {!user ? (
        <div className="flex items-center justify-center h-full">
          <button
            onClick={handleLogin}
            className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
          >
            Continue with Google
          </button>
        </div>
      ) : (
        <>
          <header className="w-full bg-ox-white sticky top-0 left-0 z-10 h-16 p-6">
            <div className="container mx-auto px-4 py-2 flex justify-between items-center">
              <Logo className="w-44 pb-4" />
              <h1 className="text-4xl font-bold">Bid Applications</h1>
              <div className="flex items-center justify-center cursor-pointer" onClick={logout}>
                <p className="mx-4">{user.name}</p>
                <img
                  src={user.picture}
                  alt="User profile"
                  className="mx-auto rounded-full h-10 w-10"
                  onError={() => setImageError(true)}
                  style={{ display: imageError ? "none" : "block" }}
                />
                {imageError && (
                  <div className="mx-auto rounded-full h-10 w-10 bg-gray-300 flex items-center justify-center">
                    <span className="text-gray-600">{user.name[0]}</span>
                  </div>
                )}
              </div>
            </div>
          </header>
          <div className="flex mt-24 max-w-screen-2xl mx-auto">
            <div className="w-3/4 px-8">
              <h2 className="text-2xl font-bold pt-12">Generate Summary</h2>
              <p className="text-gray-700">Upload a PDF and we'll create a summary of its contents.</p>
              <div className="mt-6">
                <form onSubmit={handleSummarize}>
                  <input type="file" name="file" accept=".pdf" className="mb-4 p-2 border border-gray-300 rounded" />
                  <button
                    type="submit"
                    className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
                  >
                    Generate Summary
                  </button>
                </form>
                {summaryStatus && <p className="mt-4 text-center">{summaryStatus}</p>}
              </div>

              <h2 className="text-2xl font-bold pt-12">Create Compliance Matrix</h2>
              <p className="text-gray-700">
                Upload a PDF and by golly we'll do our best to create a compliance matrix.
              </p>
              <div className="mt-6">
                <form onSubmit={handleComplianceMatrixGeneration}>
                  <input type="file" name="file" accept=".pdf" className="mb-4 p-2 border border-gray-300 rounded" />
                  <button
                    type="submit"
                    className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
                  >
                    Upload File
                  </button>
                </form>
                {complianceMatrixStatus && <p className="mt-4 text-center">{complianceMatrixStatus}</p>}
              </div>
              <p className="mt-24 text-2xl text-center">{message}</p>
            </div>
            <div className="w-1/4 px-4 border-l">
              <h2 className="text-2xl font-bold pt-12">Drive Files</h2>
              <div className="mt-6">
                {driveFiles.map((file) => (
                  <div key={file.id} className="mb-4 p-3 border rounded hover:bg-gray-50">
                    <p className="font-medium">{file.name}</p>
                    <p className="text-sm text-gray-500">Modified: {new Date(file.modifiedTime).toLocaleString()}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function App() {
  return <AppContent />;
}

export default App;
