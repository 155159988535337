import React from "react";

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 169 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M47.8016 38.8918L41.8455 30.4346L35.8893 38.8918C35.7721 39.0613 35.5767 39.1613 35.3727 39.1613H28.7045C28.1793 39.1613 27.884 38.5615 28.201 38.1443L37.7126 25.5672L29.4078 14.3286C29.0996 13.9114 29.3991 13.316 29.9157 13.316H36.6403C36.853 13.316 37.0527 13.4246 37.17 13.6028L41.8455 20.8084L46.5774 13.5985C46.6946 13.4203 46.8943 13.3116 47.107 13.3116H53.836C54.3569 13.3116 54.6565 13.9027 54.3439 14.3242L46.0391 25.5628L55.5507 38.14C55.8677 38.5572 55.5681 39.1569 55.0472 39.1569H48.3226C48.1185 39.1569 47.9232 39.057 47.806 38.8875L47.8016 38.8918Z"
        fill="#1E1115"
      />
      <path
        d="M58.4512 38.5267V13.9504C58.4512 13.5984 58.7334 13.3159 59.085 13.3159H64.6895C65.0412 13.3159 65.3233 13.5984 65.3233 13.9504V38.5267C65.3233 38.8787 65.0412 39.1612 64.6895 39.1612H59.085C58.7334 39.1612 58.4512 38.8787 58.4512 38.5267Z"
        fill="#1E1115"
      />
      <path
        d="M68.6836 26.2364C68.6836 17.3968 74.3272 12.4728 81.0865 12.4728C85.2758 12.4728 88.0716 14.2634 89.6908 16.9492H89.8037V0.634507C89.8037 0.282486 90.0859 0 90.4375 0H95.99C96.3416 0 96.6238 0.282486 96.6238 0.634507V38.5267C96.6238 38.8787 96.3416 39.1612 95.99 39.1612H90.4375C90.0859 39.1612 89.8037 38.8787 89.8037 38.5267V35.5237H89.6908C88.0716 38.2095 85.2758 40 81.0865 40C74.3272 40 68.6836 35.0761 68.6836 26.2408V26.2364ZM89.808 26.2364C89.808 21.8731 86.9559 18.9657 82.823 18.9657C78.6901 18.9657 75.8379 21.8731 75.8379 26.2364C75.8379 30.5997 78.6858 33.5072 82.823 33.5072C86.9602 33.5072 89.808 30.5997 89.808 26.2364Z"
        fill="#1E1115"
      />
      <path
        d="M111.578 26.2364C111.578 17.3967 117.222 12.4728 123.981 12.4728C128.17 12.4728 130.966 14.2633 132.585 16.9491H132.698V13.9461C132.698 13.594 132.98 13.3115 133.332 13.3115H138.885C139.236 13.3115 139.518 13.594 139.518 13.9461V38.5223C139.518 38.8743 139.236 39.1568 138.885 39.1568H133.332C132.98 39.1568 132.698 38.8743 132.698 38.5223V35.5193H132.585C130.966 38.2051 128.17 39.9956 123.981 39.9956C117.222 39.9956 111.578 35.0716 111.578 26.2364ZM132.703 26.2364C132.703 21.873 129.85 18.9656 125.718 18.9656C121.585 18.9656 118.732 21.873 118.732 26.2364C118.732 30.5997 121.58 33.5071 125.718 33.5071C129.855 33.5071 132.703 30.5997 132.703 26.2364Z"
        fill="#1E1115"
      />
      <path
        d="M169.001 23.216V38.5268C169.001 38.8788 168.719 39.1613 168.367 39.1613H162.762C162.411 39.1613 162.129 38.8788 162.129 38.5268V24.8414C162.129 20.6476 160.171 18.8571 156.819 18.8571C153.299 18.8571 150.785 20.9257 150.785 26.5233V38.5311C150.785 38.8831 150.503 39.1656 150.151 39.1656H144.599C144.247 39.1656 143.965 38.8831 143.965 38.5311V13.9548C143.965 13.6028 144.247 13.3203 144.599 13.3203H150.095C150.446 13.3203 150.728 13.6028 150.728 13.9548V17.236H150.841C152.574 14.2721 155.478 12.4816 159.281 12.4816C164.868 12.4816 169.005 15.615 169.005 23.2204L169.001 23.216Z"
        fill="#1E1115"
      />
      <path
        d="M14.3044 12.4771C6.09075 12.4771 0 18.1268 0 26.2364C0 34.3459 6.09075 39.9956 14.3044 39.9956C22.518 39.9956 28.6087 34.3459 28.6087 26.2364C28.6087 18.1268 22.518 12.4728 14.3044 12.4728V12.4771ZM7.29328 23.6331L14.5996 17.7401C15.0207 17.4011 15.6241 17.4011 16.0452 17.7401L21.9536 22.5293C22.3357 22.8422 22.4789 23.3637 22.3096 23.8244L19.5529 31.2603C19.414 31.6297 19.0971 31.9078 18.7107 31.986L8.45239 34.1373C7.74911 34.285 7.08056 33.7548 7.06319 33.0377L6.86349 24.5545C6.85481 24.1981 7.01544 23.8548 7.29328 23.6288V23.6331Z"
        fill="#1E1115"
      />
      <path
        d="M58.5851 5.33249L62.7353 1.98611C62.974 1.79055 63.317 1.79489 63.5558 1.98611L66.9115 4.70667C67.1286 4.88485 67.2111 5.18038 67.1112 5.44113L65.5441 9.66104C65.4659 9.87399 65.2836 10.0261 65.0665 10.0739L59.2406 11.2951C58.8412 11.3777 58.4635 11.0778 58.4548 10.6693L58.3419 5.854C58.3376 5.64974 58.4288 5.45852 58.5851 5.32814V5.33249Z"
        fill="url(#paint0_linear_1503_20)"
      />
      <path
        d="M101.467 38.5267V13.9504C101.467 13.5984 101.749 13.3159 102.101 13.3159H107.705C108.057 13.3159 108.339 13.5984 108.339 13.9504V38.5267C108.339 38.8787 108.057 39.1612 107.705 39.1612H102.101C101.749 39.1612 101.467 38.8787 101.467 38.5267Z"
        fill="#1E1115"
      />
      <path
        d="M101.607 5.33249L105.757 1.98611C105.996 1.79055 106.338 1.79489 106.577 1.98611L109.933 4.70667C110.15 4.88485 110.233 5.18038 110.133 5.44113L108.566 9.66104C108.487 9.87399 108.305 10.0261 108.088 10.0739L102.262 11.2951C101.863 11.3777 101.485 11.0778 101.476 10.6693L101.363 5.854C101.359 5.64974 101.45 5.45852 101.607 5.32814V5.33249Z"
        fill="url(#paint1_linear_1503_20)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1503_20"
        x1="-2.40497"
        y1="18.1008"
        x2="159.886"
        y2="-11.2726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C11D6F" />
        <stop offset="0.99" stopColor="#D02028" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1503_20"
        x1="-1.03764"
        y1="25.6541"
        x2="161.253"
        y2="-3.71498"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C11D6F" />
        <stop offset="0.99" stopColor="#D02028" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
